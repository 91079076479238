import React, {useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../../header/Header";
import Sidebarr from "../../../sidebar/Sidebar";
import Tool from "../../../sidebar/Tool";
import Footer from "../../../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { Data } from './Data'
import { format } from "date-fns"
import moment, { Moment } from "moment/moment";
import { GetPatientBPDetailsAction} from "../../../action"
import axios from "../../../helper/axios";
//import { Accordion, Card, Button } from "react-bootstrap"
import Accordion from 'react-bootstrap/Accordion';
import './ViewPatientReport.css';
// import { Chart } from "../../sync/Chart";


export default function ViewPatientReport() {
  const { patientId } = useParams();
  const {
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state);
  const obj = {};
  const [inputField, setInputField] = useState(obj);

  const inputsHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    inputField[name] = value;
    setInputField(inputField);
  };
  
  const token = useSelector((state) => state?.auth?.token);
  const patientData = useSelector((state) => state?.patient?.data);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [diseases, setDiseases] = useState("");
  const [otherDiseases, setOtherDiseases] = useState("");
  const [medications, setMedications] = useState("");
  const [allergies, setAllergies] = useState("");
  const [report, setReport] = useState();
  const [active, setActive] = useState("");

  const otherDisease = [];
  const medicineArray = [];
  const allergyArray = [];
  if (otherDiseases && otherDiseases.length > 0) {
    otherDiseases.split(/\s*,\s*/).forEach(function (otherDis) {
      otherDisease.push(otherDis);
    });
  }

  if (medications && medications.length > 0) {
    medications.split(/\s*,\s*/).forEach(function (medication) {
      medicineArray.push(medication);
    });
  }

  if (allergies && allergies.length > 0) {
    allergies.split(/\s*,\s*/).forEach(function (allergy) {
      allergyArray.push(allergy);
    });
  }



  const fliterItem = async(type, event) => {
    //alert(type)
    setActive(event.target.id);
    var data = patientId;
    let i=0;
    const res = await axios.get(`/get-all-records?patientId=${data}&interval=`+type,data);
    //setYearlyReport(res.data.data);
    //console.log('report23',res.data.data.data); 
    setReport(res.data.data.data);
     
  };

  useEffect(async()=>{
    //await dispatch(GetPatientBPDetailsAction(patientId,token))
    var data = patientId;
    let i=0;
    setActive("4");
    const res = await axios.get(`/get-all-records?patientId=${data}&interval=year`,data);
    //console.log('report235',res);
    setReport(res.data.data.data); 
  },[])

  useEffect(() => {
    setEditorLoaded(true);
    const data = patientData.find((obj) => {
      return obj._id === patientId;
    });

  
  
    // useEffect(fliterItemYear, []);
    
    // useEffect(fliterItemWeek, []);
    
    // useEffect(fliterItemMonth, []);
    
   

    setDiseases(data?.diseases);
    setOtherDiseases(data?.otherDiseases);
    setMedications(data?.medication);
    setAllergies(data?.allergies);
  }, []);

  return (
    <div className="sidebar-mini skin-gr0een-light">
      <div>
        <Toaster />
      </div>
      <div className="wrapper">
        <Header />
        <Sidebarr />
        <div className="content-wrapper">
          <section className="content-header">
            <h1>View Patient Reports</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/home">
                  <i className="fa fa-dashboard" /> Home
                </a>
              </li>
              <li>
                <a href="/patients">Manage patient</a>
              </li>
              <li style={{fontWeight:"bold"}}>View patient reports</li>
            </ol>
          </section>
          <section className="content">
            <div className="box">
              <div className="box-header with-border">
                <div className="box-tools pull-right">
                  <Link
                    to="/patients"
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    title="Back"
                  >
                    {"Go Back"}
                  </Link>
                </div>
              </div>

              <div className="box-body">
                <section className="content">
                  <div className="row">
                    <div className="col-md-4">
                    <div>
                    <button
                    className={
                      active === "1"
                        ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
                        : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
                    }
                    onClick={(e) => fliterItem("hour", e)}
                    key={1}
                    id={"1"}
                    >
                    Hourly
                    </button>&nbsp;&nbsp;
                    <button
                    className={
                      active === "2"
                        ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
                        : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
                    }
                    onClick={(e) => fliterItem("week", e)}
                    key={2}
                    id={"2"}
                    >
                    Weekly
                    </button>&nbsp;&nbsp;
                    <button
                    className={
                      active === "3"
                        ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
                        : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
                    }
                    onClick={(e) => fliterItem("month", e)}
                    key={3}
                    id={"3"}
                    >
                    Monthly
                    </button>&nbsp;&nbsp;
                    <button
                    className={
                      active === "4"
                        ? "active btn-dark text-white p-1 px-2 mx-5 btn fw-bold btnActive"
                        : "btn-dark text-white p-1 px-2 mx-5 btn fw-bold"
                    }
                    onClick={(e) => fliterItem("year", e)}
                    key={4}
                    id={"4"}
                    >
                    Yearly
                    </button>

                    <Accordion defaultActiveKey="0" flush>
                    {(report && report.length > 0 ? <>
                      {report.map((res,i) => (

      <Accordion.Item eventKey={i}>
      <Accordion.Header>Click To See Test Report On {moment(res.createdAt).format("DD/MM/YYYY HH:mm")}</Accordion.Header>
                  <Accordion.Body> 
                          <div className="col-md-12"><p><h4 className="report-heading">Systolic:</h4><span style={{'line-height': '40px', 'padding-left': '20px', 'font-weight': '600'}}>{res.systolic}</span></p></div>
                          <div className="col-md-12"><p><h4 className="report-heading">Diastolic:</h4><span style={{'line-height': '40px', 'padding-left': '20px', 'font-weight': '600'}}>{res.diastolic}</span></p></div>
                          <div className="col-md-12"><p><h4 className="report-heading">Pulse:</h4><span style={{'line-height': '40px', 'padding-left': '20px', 'font-weight': '600'}}>{res.pulse}</span></p></div>
                          <div className="col-md-12"><p><h4 className="report-heading">Note:</h4><span style={{'line-height': '40px', 'padding-left': '20px', 'font-weight': '600'}}>{res.note}</span></p></div>
                  </Accordion.Body>
                  </Accordion.Item>

                      ))}
                    </> : <div style={{marginTop: "30px"}}><b style={{fontSize: "18px"}}>No BP Records Available</b>
                      </div>)}
                  </Accordion>
                </div>
                    </div>
                    {/* <div className="col-md-8">
                    <Chart/>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <Tool />
        <div className="control-sidebar-bg" />
      </div>
    </div>
  );
}
