import React,{useEffect,useState} from 'react'
import {Link} from "react-router-dom";
import Header from '../../../header/Header';
import Sidebarr from '../../../sidebar/Sidebar';
import { GetDoctorAction,DeleteDoctorAction} from "../../../action"
import Tool from '../../../sidebar/Tool';
import Footer from '../../../footer/Footer';
import {useSelector,useDispatch} from "react-redux";
import { Toaster } from 'react-hot-toast';

export default function DoctorList() {
  const auth = useSelector(state=>state.auth);
  const doctorData = useSelector(state=>state?.doctor?.data?.data)
  const dispatch = useDispatch();
  
  useEffect(async()=>{
    await dispatch(GetDoctorAction())
  },[])

  
  const refreshDoctorList = async() => {
    await dispatch(GetDoctorAction());
  }
  const deleteDoctor = async(id)=>{
    const data={id:id}
    await dispatch(DeleteDoctorAction(data))
    await refreshDoctorList();
  }

	return (
		<div className="sidebar-mini skin-green-light">
      <div><Toaster/></div>
		<div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/>
         
  {/* Content Wrapper. Contains page content */}
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <section className="content-header">
      <h1>
        Doctors  List
      </h1>
      <ol className="breadcrumb">
        <li>
          <a href="/home">
            <i className="fa fa-dashboard" /> Home
          </a>
        </li>
        <li>
          <a style={{fontWeight:"bold"}}>Manage Doctors</a>
        </li>
      </ol>
    </section>
    {/* Main content */}
    <section className="content">
      {/* Default box */}
      <div className="box">
        <div className="box-header with-border"> 
          <div className="box-tools pull-right">

            <Link
              // to="/home"
              to="/add-doctor"
              type="button"
              className="btn btn-warning" 
              data-toggle="tooltip"
              title="Add"
            >
              <i className="fa fa-plus" />
              {" Add New Doctor "}
            </Link>
            {" "}
          </div>
        </div>
        <div className="box-body">
        	<table className="table table-bordered table-striped dataTable" id="example2">
        	  <thead>
        	    <tr>
                    <th>Name</th>
                    {/* <th>Designation</th> 
                    <th>Specialization</th> */}
                    <th>Phone Number</th>
                    <th>Email</th>
                    {/* <th>No. of Patients</th> */}
                    <th>Action</th>
        	  	</tr>
        	  </thead>
        	  <tbody>
              
            {doctorData?.length>0 && doctorData?.map((dt)=>(
              <tr>
                <td>
                  <span className="text-uppercase fs-1"><p>{dt?.fname} {dt?.lname}</p></span>
                </td>  
                {/* <td>
                  <span className="text fs-1">{dt?.designation}</span>
                </td>
                <td>
                  <span className="text fs-1">{dt?.specialization}</span>
                </td> */}
                <td>
                  <span className="text fs-1">{dt?.contact}</span>
                </td>
                <td>
                  <span className="text-lowercase fs-1">{dt?.email}</span>
                </td>
                {/* <td>
                  <span className="text fs-1">{dt?.patientCount}</span>
                </td> */}
                <td>
                  <Link title="Edit" to={"/edit-doctor/"+dt?._id} className="btn btn-default">
                    <i className="fa fa-edit"></i>
                  </Link>
                  {" "} 
                  {" "}
                  <button title="Delete" className="btn btn-default" onClick={(e)=>deleteDoctor(dt?._id)}>
                    <i className="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              ))}
        	  </tbody>
        	</table>
        </div>
        {/* /.box-body */}
        {/*<div className="box-footer">Footer</div>*/}
        {/* /.box-footer*/}
      </div>
      {/* /.box */}
    </section>
    {/* /.content */}
  </div>
  {/* /.content-wrapper */}
       <Footer/>
       <Tool/> 
  <div className="control-sidebar-bg" />
</div>	
</div>
)}