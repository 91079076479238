import React, {useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../../header/Header";
import Sidebarr from "../../../sidebar/Sidebar";
import Tool from "../../../sidebar/Tool";
import Footer from "../../../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import moment, { Moment } from "moment/moment";
import { EditPrescriptionAction} from "../../../action"
import axios,{baseUrl} from "../../../helper/axios";
import Accordion from 'react-bootstrap/Accordion';
import './ViewPatientReport.css';
export default function ViewPatientPrescription() {
  const { patientId } = useParams();
  const {
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state);
  const obj = {};
  const [inputField, setInputField] = useState(obj);

  const inputsHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    inputField[name] = value;
    setInputField(inputField);
  };
  
  const token = useSelector((state) => state?.auth?.token);
  const patientData = useSelector((state) => state?.patient?.data);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [diseases, setDiseases] = useState("");
  const [otherDiseases, setOtherDiseases] = useState("");
  const [medications, setMedications] = useState("");
  const [allergies, setAllergies] = useState("");
  const [prescription, setPrescription] = useState(10);
  const [medicine, setMedicines] = useState();
  const [listItems, setListItems] = useState(20);

  const otherDisease = [];
  const medicineArray = [];
  const allergyArray = [];
  if (otherDiseases && otherDiseases.length > 0) {
    otherDiseases.split(/\s*,\s*/).forEach(function (otherDis) {
      otherDisease.push(otherDis);
    });
  }

  if (medications && medications.length > 0) {
    medications.split(/\s*,\s*/).forEach(function (medication) {
      medicineArray.push(medication);
    });
  }

  if (allergies && allergies.length > 0) {
    allergies.split(/\s*,\s*/).forEach(function (allergy) {
      allergyArray.push(allergy);
    });
  }

  const onSubmit = async(resp, prescriptionId) => { 
    const medData={
      medicineId : resp._id,
      prescriptionId : prescriptionId,
      medStatus : resp.isActive
   } 
    await dispatch(EditPrescriptionAction(medData));
    const res = await axios.get(`/get-user-prescription?patientId=${patientId}`,patientId);
    await setPrescription(res.data.data);
 }

  useEffect(async()=>{
    var data = patientId;
    let i=0;
    const res = await axios.get(`/get-user-prescription?patientId=${data}`,data);
    setPrescription(res.data.data); 
  },[]);

  useEffect(() => {
    setEditorLoaded(true);
    const data = patientData.find((obj) => {
      return obj._id === patientId;
    });

    setDiseases(data?.diseases);
    setOtherDiseases(data?.otherDiseases);
    setMedications(data?.medication);
    setAllergies(data?.allergies);
  }, []);

  console.log("Prescription2 -====", prescription)

  return (
    <div className="sidebar-mini skin-gr0een-light">
      <div>
        <Toaster/>
      </div>
      <div className="wrapper">
        <Header />
        <Sidebarr />
        <div className="content-wrapper">
          <section className="content-header">
            <h1>View Patient Prescription:</h1>
            <ol className="breadcrumb">
              <li>
                <a href="/home">
                  <i className="fa fa-dashboard" /> Home
                </a>
              </li>
              <li>
                <a href="/patients">Manage patient</a>
              </li>
              <li style={{fontWeight:"bold"}}>View Patient Prescription</li>
            </ol>
          </section>
          <section className="content">
            <div className="box">
              <div className="box-header with-border">
                <div className="box-tools pull-right">
                <Link
                    to={"/addprescription/"+patientId}
                    type="button"
                    className="btn btn-warning" 
                    data-toggle="tooltip"
                    title="Add"
                    >
                    <i className="fa fa-plus" />
                    {" Add New Prescription "}
                    </Link>
                    {" "}
                  <Link
                    to="/patients"
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    title="Back"
                  >
                    {"Go Back"}
                  </Link>
                </div>
              </div>

              <div className="box-body">
                <section className="content">
                  <div className="row">
                    <div className="col-md-12">
                    <div>
                    <Accordion defaultActiveKey="0" flush>
                   
                    {(prescription && prescription.length > 0  ? <>
                    {console.log('prescription_count',prescription.length)}   
                      {prescription.map((res,i) => (

                        
                  <Accordion.Item eventKey={i}>
                    <div className="panel-header col-md-8">
                    <Accordion.Header>Click To See Prescription On {moment(res.createdAt).format("DD/MM/YYYY HH:mm")}</Accordion.Header>
                    </div>
                    <div className="panel-header-active col-md-4">
                    {(() => {
                    if (
                      res?.isActive === true
                    ) {
                      return (
                    
                      <Accordion.Button>{res.isActive}</Accordion.Button>
                    
                    );}})()}
                    </div>
                    <div className="panel-prescription-body col-md-12">
                    <Accordion.Body style={{'paddingBottom': '20px'}}> 
                          {/* <div className="col-md-12"><p><h4 className="report-heading">Diagnosis:</h4><span style={{'line-height': '40px', 'padding-left': '20px', 'font-weight': '300'}}>{res.diagnosis}</span></p></div>
                          <div className="col-md-10"><p><h4 className="report-heading">Do Not Use:</h4><span style={{'line-height': '40px', 'padding-left': '20px', 'font-weight': '300'}}>{res.doNotUse}</span></p></div> */}
                          
                          {/* <div className="col-md-10"><p><h4 className="report-heading">Medicines:</h4><span style={{'line-height': '40px', 'padding-left': '20px', 'font-weight': '300'}}>{console.log("Prescription3 -=",res.medicines)}</span></p></div><hr/> */}
                          <div className="col-md-10">
                          <table>
                                  <tr>
                                    <th>No. </th>
                                    <th>Drug</th>
                                    <th>Size</th>
                                    <th>SIG ( how to take )</th>
                                    <th>Dispense(quantity)</th>
                                    <th>Refill</th>
                                    <th>Status</th>
                                    <th></th>
                                  </tr>
                          
                          
                          {
                            (res.medicines && res.medicines.length > 0 ? <>
                            {res.medicines.map((resp,i) => (
                                <>
                                
                                <tr>
                                  <td>{i+1}</td>
                                  <td>{resp.name}</td>
                                  <td>{resp.dosage}</td>
                                  <td>{resp.frequency}</td>
                                  <td>{resp.timePeriod}</td>
                                  <td>{resp.refill}</td>
                                  {(() => {
                                    if (
                                      resp?.isActive === true
                                    ) {
                                      return (
                                        <td>Active</td>
                                    )} else {
                                      return(
                                        <td>Stopped</td>
                                      )
                                    }
                                  })()}
                                  {(() => {
                                    if (
                                      resp?.isActive === true
                                    ) {
                                      return (
                                        <td onClick={(e)=>onSubmit(resp, res._id)} style={{color:"red",fontWeight:"bold"}}>Stop the medication</td>

                                    )}
                                  })()}
                                </tr>
                                {/* <div className="col-md-12"><h6 className="report-heading"><span>{i+1}. </span>Drug: <b>{res.name}</b></h6></div>
                                <div className="col-md-9"><p><h6 className="report-heading">Size: <b>{res.dosage}</b></h6></p></div>
                                <div className="col-md-9"><p><h6 className="report-heading">SIG ( how to take ): <b>{res.frequency}</b></h6></p></div>
                                <div className="col-md-9"><p><h6 className="report-heading">Dispense(quantity): <b>{res.timeperiod}</b></h6></p></div>
                                <div className="col-md-9"><p><h6 className="report-heading">Refill: <b>{res.refill}</b></h6></p></div>                                */}
                                </>
                             
                            ))}</>: <div className="col-md-12"><p><h6 className="report-heading"><b>No Medicine Found</b></h6></p></div>)}
                          </table>
                          </div>
                          <div className="col-md-2">
                          {res.pdf && 
                          <a class="downloadbtn" href={baseUrl+"prescriptions/"+res.pdf} download={"prescriptions/"+res.pdf} target='_blank'>
                            <button title="Download Prescription" className="btn btn-default">
                              <i className="fa fa-download"></i>
                            </button>
                          </a>
                          }
                          </div>
                          
                           
                  </Accordion.Body>
                    </div>
                  
                                   
                  {/* <button style={{ marginLeft:"367px",marginTop:"-81px",fontSize:"13px"}} title="Download Prescription" className="btn btn-default">
                    <i className="fa fa-download"></i>
                  </button> */}
                  </Accordion.Item>

                      ))}
                    </> : <b style={{marginTop: "30px", fontSize: "18px"}}>No Prescription Found</b>)}
                    

                  </Accordion>
                </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        <Tool />
        <div className="control-sidebar-bg" />
      </div>
    </div>
  );
}
