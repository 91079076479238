import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginPage } from "../action";
import { Toaster, toast } from "react-hot-toast";

// import {logo} from '../../public/logo.png';

export default function Login() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onKeyDownHandler = (e) => {
    if (e?.key === 'Enter') {
      loginData();
    } 
  };

  const loginData = () => {
    const data = { email, password };
    if(data.email === '' || data.password === ''){
      toast.error("Email or Password cannot be empty.");
    } else {
      dispatch(LoginPage(data));
    }
    
  };

  return (
    <div className="hold-transition login-page" style={{position:"absolute", minHeight: "100%", minWidth: "100%", backgroundImage: "radial-gradient(white, rgb(171 168 168))"}}>
      <div>
        <Toaster />
      </div>
      <div className="login-box">
        
        <div className="login-box-body" style={{ borderRadius: "4%" }}>

          <div className="login-logo">
          <a href="../../index2.html">
            <a
              aria-current="page"
              className="MobileHeaderTop__logo active"
              href="#"
            >
              <img src="dist/img/logo2.png" style={{ height: "100px" }}></img>
            </a>
          </a>
        </div>
          <p className="login-box-msg" style={{ fontWeight: "bold", fontSize:"17px", fontFamily: "sans-serif" }}>
            Sign in
          </p>
          <form>
            <div className="form-group has-feedback">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                style={{ borderRadius: "7px" }}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => onKeyDownHandler(e)}
              />
              <span className="glyphicon glyphicon-envelope form-control-feedback icoColor" />
            </div>
            <div className="form-group has-feedback">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                style={{ borderRadius: "7px" }}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => onKeyDownHandler(e)}
              />

              <span className="glyphicon glyphicon-lock form-control-feedback" />
            </div>
            <div className="row">
              <div className="col-xs-4" style={{ left: "137px" }}>
                <button
                  type="button"
                  className="btn btn-warning btn-block btn-flat"
                  style={{
                    backgroundColor: "#DF1721",
                    borderColor: "#690E14",
                    borderRadius: "7px",
                  }}
                  onClick={(e) => loginData()}
                >
                  Sign In
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
