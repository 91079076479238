import React,{useEffect,useState} from 'react'
import {Link} from "react-router-dom";
import Header from '../../../header/Header';
import Sidebarr from '../../../sidebar/Sidebar';
import { GetNotificationAction } from "../../../action"
import Tool from '../../../sidebar/Tool';
import Footer from '../../../footer/Footer';
import {useSelector,useDispatch} from "react-redux";
import { Toaster } from 'react-hot-toast';

export default function NotificationList() {
  const auth = useSelector(state=>state.auth);
  const notificationData = useSelector(state=>state?.notification?.data?.data)
  const dispatch = useDispatch();
  
  useEffect(async()=>{
    await dispatch(GetNotificationAction())
  },[])

	return (
		<div className="sidebar-mini skin-green-light">
      <div><Toaster/></div>
		<div className="wrapper"> 
  		  <Header/> 
  		  <Sidebarr/>
         
  {/* Content Wrapper. Contains page content */}
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <section className="content-header">
      <h1>
        Notifications  List
      </h1>
      <ol className="breadcrumb">
        <li>
          <a href="/home">
            <i className="fa fa-dashboard" /> Home
          </a>
        </li>
        <li>
          <a style={{fontWeight:"bold"}}>Manage Notifications</a>
        </li>
      </ol>
    </section>
    {/* Main content */}
    <section className="content">
      {/* Default box */}
      <div className="box">

        <div className="box-body">
        	<table className="table table-bordered table-striped dataTable" id="example2">
        	  <thead>
        	    <tr>
                    <th>Title</th>
                    <th>Action</th>
        	  	</tr>
        	  </thead>
        	  <tbody>
              
            {notificationData?.length>0 && notificationData?.map((dt)=>(
              <tr>
                {(() => {
                    if (
                      dt?.isRead === false
                    ) {
                      return (
                        <td>
                        <span className="text-uppercase fs-1" style={{color:"red",fontWeight:"bold"}}><p>{dt?.title}</p></span>
                        </td>
                    
                    );}else {
                      return (
                      <td>
                        <span className="text-uppercase fs-1" style={{fontWeight:"bold"}}><p>{dt?.title}</p></span>
                      </td>
                      );
                    }})()}
                  
                <td>
                <Link title="View" to={"/view-notification/"+dt?._id} className="btn btn-default">
                    <i className="fa fa-eye" />
                </Link>
                </td>
              </tr>
              ))}
        	  </tbody>
        	</table>
        </div>
        {/* /.box-body */}
        {/* /.box-footer*/}
      </div>
      {/* /.box */}
    </section>
    {/* /.content */}
  </div>
  {/* /.content-wrapper */}
       <Footer/>
       <Tool/> 
  <div className="control-sidebar-bg" />
</div>	
</div>
)}