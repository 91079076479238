import React from 'react'

export default function Footer() {
	return (
		<div>
		 <footer className="main-footer">
    <div className="pull-right hidden-xs">
      <b>Version</b> 2.4.18
    </div>
    <strong>
      Copyright © 2022 <a href="#">Health Care</a>.
    </strong>{" "}
    All rights reserved.
  </footer>
			
		</div>
	)
}