import {prescriptionStatus} from '../action/Status';

const initialData = {
	data:Number,
};

export default (state=initialData,action)=>{
	 console.log(action)
	 switch(action.type){
	 	case prescriptionStatus.prescription_REQUEST:
	 	    state={
	 	    	...state
 			}
	 	break; 
	 	case prescriptionStatus.PRESCRIPTION_GET_REQUEST:
	 	    state={
	 	    	...state
 			}
	 	break;
	 	case prescriptionStatus.PRESCRIPTION_GET_SUCCESS:
	 	    state={
	 	    	...state,
	 	    	data:action.payload.prescriptions

	 	    }
	 	break;
         case prescriptionStatus.PRESCRIPTION_GET_FAILED:
            state={
                ...state
            }
        break;
	 }

	 return state;
}